import React from 'react'
import { Link } from 'react-router-dom'

import facebook from '../../assets/icons/Facebook.svg'
import twitter from '../../assets/icons/Twitter.svg'
import instagram from '../../assets/icons/Instagram.svg'

import './css/style.css'

const Toolbar = props => {
  const { home } = props

  return (
    <div
      className='general-body'
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        background: !home ? 'var(--primary-background)' : 'none'
      }}
    >
      <div id='toolbar'>
        <Link to='/' style={{ textDecoration: 'none' }}>
          <div className='logo'>
            <img src={`/images/logo.png`} alt='logo' />
            <h1>BLACKSTEEL</h1>
          </div>
        </Link>
        <div>
          <a href='https://www.facebook.com/useblacksteel' target='_blank'>
            <img src={facebook} alt='facebook' />
          </a>
          <a href='https://twitter.com/useblacksteel' target='_blank'>
            <img src={twitter} alt='twitter' />
          </a>
          <a href='https://www.instagram.com/useblacksteel' target='_blank'>
            <img src={instagram} alt='instagram' />
          </a>
        </div>
      </div>
    </div>
  )
}

export default Toolbar
