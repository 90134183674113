export const offers_ = [
  {
    title: 'Easy to Start',
    info:
      'Simply connect your bank account and get approved with an active card#.',
    imageName: 'pre-approved card.png'
  },
  {
    title: 'Best in Class Rewards',
    info:
      'Receive 1% cashback on all purchases and up to 3% with select partners. No points BS.',
    imageName: 'wallet.png'
  },
  {
    title: 'Make Paying Easy with Auto-Pay',
    info:
      'Setup weekly, bi-weekly, and monthly auto-pay so you never miss a payment.',
    imageName: 'autopay.png'
  },
  {
    title: '0% APR Interest Rate',
    info:
      'We don’t charge interest on any purchases or balances. The most importnant money is the money you keep.',
    imageName: 'zero-interest.png'
  },
  {
    title: 'Reports to All 3 Major Credit Bureaus',
    info:
      'Every Payment gets reported to all 3 major credit bureaus to help you build credit.',
    imageName: 'house.png'
  },
  {
    title: 'No Fees Ever',
    info:
      'You get a premium credit card with no fees. We’re here to help you grow your wealth, not take it.',
    imageName: 'no-fees.png'
  }
]

export const benefits_ = [
  {
    title: 'We Grow With You',
    info: 'A credit limit model that’s flexible and grows as fast as you grow.',
    imageName: 'growth.jpg'
  },
  {
    title: 'All Rewards, No fees.',
    info:
      'Just rewards, no risk. One of the only cards with 1% cashback and no fees.',
    imageName: 'rewards.jpg'
  },
  {
    title: 'Next Gen Smart Card',
    info:
      'Mobile friendly smart card with next gen features built for life on the go.',
    imageName: 'smart.jpg'
  }
]

export const targets_ = [
  {
    title: 'Easily Manage Your Card with Our Mobile App.',
    info: `Blacksteel’s best in class tech makes takes the headache out of staying on top of your purchases, payments, and account balances with a host of automated tools.`,
    imageName: 'target 1.png'
  },
  {
    title: 'Hit Your Financial & Credit Score Goals',
    info: `We’re about cashflow so it’s our job to help you grow yours. Create budgets using our spend management tools so you can track and control when, where, and who you spend money with.`,
    imageName: 'target 2.png'
  },
  {
    title: 'Spend Confidently with Modern Safety & Security',
    info: `Use cellphone biometrics to approve purchases and receive real-time transaction notifications with fraud monitoring.`,
    imageName: 'target 3.png'
  }
]

export const cards_ = [
  {
    name: 'Standard Card',
    info: '1% Cashback on all purchases',
    imageName: 'standard-card-rotated.png'
  },
  {
    name: 'Premium Card',
    info: '20g Tungsten Steel Card More Cashback + Rewards',
    imageName: 'premium-card-rotated.png'
  }
  // {
  //   name: 'Platinum Card',
  //   info: 'Hit level 3 goals and recieve a Metal Card in Platinum',
  //   imageName: 'platinum card.png'
  // },
  // {
  //   name: 'Black Card',
  //   info: 'Hit level 4 goals and recieve a Metal Card in Black',
  //   imageName: 'black card.png'
  // }
]
