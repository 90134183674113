import React from 'react'

const rewards_ = [
  {
    value: { type: 'text', val: '$30' },
    info: 'Credit for you and the person you referred',
    referrals: '1'
  },
  {
    value: { type: 'image', val: 'standard card 2.png' },
    info: 'Move up In line to get your Blacksteel credit card early',
    referrals: '2-9'
  },
  {
    value: { type: 'image', val: 'black card 2.png' },
    info: 'Get our 20g tungsten steel Blacksteel premier member card',
    referrals: '10'
  }
]
const Reward = props => {
  return (
    <div className='reward'>
      <div className='sm'>
        <div className='ref-count'>
          <div>{props.reward.referrals}</div>
          <span>Referrals</span>
        </div>
        {props.reward.value.type === 'image' ? (
          <img
            src={`/images/${props.reward.value.val}`}
            alt={props.reward.value.val}
          />
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              maxWidth: '90px',
              color: 'var(--primary-color)',
              fontSize: '3em',
              fontWeight: '600'
            }}
          >
            <span>{props.reward.value.val}</span>
          </div>
        )}
        <div style={{ whiteSpace: 'pre-wrap' }} className='info'>
          {props.reward.info}
        </div>
      </div>
      <div className='bg'>
        <div className='img-container'>
          {props.reward.value.type === 'image' ? (
            <img
              src={`/images/${props.reward.value.val}`}
              alt={props.reward.value.val}
            />
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                color: 'var(--primary-color)',
                fontSize: '3em',
                fontWeight: '600'
              }}
            >
              {props.reward.value.val}
            </div>
          )}
        </div>
        <div className='ref-count'>
          <div>{props.reward.referrals}</div>
          <span>Referrals</span>
        </div>
        <div className='center-text info' style={{ whiteSpace: 'pre-wrap' }}>
          {props.reward.info}
        </div>
      </div>
    </div>
  )
}
const Rewards = () => {
  const rewards = rewards_.map((r, index) => {
    return <Reward reward={r} key={index}></Reward>
  })
  return <div className='rewards'>{rewards}</div>
}

export default Rewards
