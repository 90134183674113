import React, { useState, useEffect, useRef } from 'react'
import Footer from './sections/footer.section'
import Header from './sections/header.section'
import Body from './sections/body.section'
import ApprovalDialog from '../../components/approval-dialog/approvalDialog.component'
import Snackbar from '../../components/snackbar/snackbar.component'

import { clearResponse } from '../../redux/actions/response.actions'
import { inputChange } from '../../redux/actions/user.actions'
import { connect } from 'react-redux'
import { analytics } from '../../assets/js/firebaseConfig'

import './css/homepageHeader.css'
import './css/homepageBody.css'
import './css/homepageFooter.css'

const HomePage = props => {
  const { response, dbUser } = props
  const [showDialog, setShowDialog] = useState(false)
  const [snack, setSnack] = useState({ show: false, message: '' })

  const mounted = useRef(false)

  useEffect(() => {
    if (mounted.current) {
      if (response.id) {
        if (response.id === 'SEND_INFO_ERROR') {
          showSnack(response.msg)
          props.clearResponse()
        }
      }
    } else {
      window.scrollTo(0, 0);
      mounted.current = true
    }
  })

  useEffect(() => {
    if (showDialog) {
      document.body.style.overflow = 'hidden'
      analytics.logEvent('opened_pre-approval_dialog')
      // props.inputChange('registered', false)
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [showDialog])

  const showDialog_ = () => {
    setShowDialog(true)
  }
  const hideDialog = () => {
    setShowDialog(false)
  }
  const showSnack = (message, type, timeout) => {
    setSnack({ show: true, message, type, timeout })
  }

  return (
    <div id='homepage'>
      <Snackbar
        show={snack.show}
        message={snack.message}
        type={snack.type}
        timeout={snack.timeout}
        onClose={() => {
          setSnack({ ...snack, show: false })
        }}
      />
      <Header onShowDialog={showDialog_} onShowSnackbar={showSnack} />
      <Body />
      <Footer onShowDialog={showDialog_} onShowSnackbar={showSnack} />
      <ApprovalDialog
        onClose={hideDialog}
        show={showDialog}
        onShowSnackbar={showSnack}
      />
    </div>
  )
}
const mapStateToProps = state => ({
  response: state.response,
  dbUser: state.user.dbUser
})
export default connect(mapStateToProps, { clearResponse, inputChange })(
  HomePage
)
