import React, { useState } from 'react'
import './css/textField.css'

const TextField = props => {
  const [focused, setFocused] = useState(false)
  const [type, setType] = useState(props.type || 'text')
  const handleInput = e => {
    if (props.onInput) props.onInput(e)
  }

  const handleBlur = () => {
    if (props.onBlur) props.onBlur()
    setFocused(false)
    if (props.validator)
      props.validator.showMessageFor(props.validationData.for)
  }
  const handleFocus = () => {
    if (props.onFocus) props.onFocus()
    setFocused(true)
  }

  return (
    // <div
    //   id='text-field'
    //   className={`${props.className || ''}`}
    //   style={{ ...props.style }}
    // >
      <input
        id={props.id}
        type={type}
        value={props.value}
        onChange={handleInput}
        style={{
          width: props.width || '100%',
          borderColor: props.outlined
            ? focused
              ? 'var(--primary-color)'
              : props.color || 'var(--primary-dark-text)'
            : '',
          backgroundColor: !props.outlined ? props.color || 'white' : 'inherit',
          padding: '.8em 1.5em',
          ...props.inputStyle
        }}
        autoComplete={props.autoComplete}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder={props.placeholder}
        className={`${
          props.outlined ? 'outlined' : !props.depressed ? 'general-shadow' : ''
        }`}
        readOnly={props.readOnly}
        name={props.name}
      />
  )
}

export default TextField
