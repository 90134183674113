import React from 'react'
import './css/offer.css'

const Offer = props => {
  const { title, info, imageName } = props.offer
  return (
    <div className='offer'>
      <h3>{title}</h3>
      <p>{info}</p>
      <img src={`/images/${imageName}`} alt='' />
    </div>
  )
}
export default Offer
