import API from "../../assets/js/api";
import { analytics } from "../../assets/js/firebaseConfig";

export const inputChange = (name, value) => (dispatch) => {
  dispatch({ type: "INPUT_CHANGE", payload: { name, value } });
};
export const clearState = () => (dispatch) => {
  dispatch({ type: "CLEAR_STATE", payload: null });
};
export const sendInfo = (approvalAmount) => async (dispatch, getState) => {
  try {
    const user = getState().user;
    const income = parseFloat(user.income);
    const accountBalance = parseFloat(user.accountBalance);
    const { email, firstName, lastName, phoneNumber, kolInfo } = user;

    const sendData = {
      email,
      firstName,
      lastName,
      phoneNumber,
      approvalAmount,
      income,
      accountBalance,
      KOL: {
        social_url: kolInfo.social_url,
        status_url: kolInfo.status_url,
        social_id: kolInfo.social_id,
      },
    };

    const result = await API.addUser(sendData);
    analytics.logEvent("successful_pre-approval");
    if (result.data.errs.length) {
      result.data.errs.forEach((err) => {
        analytics.logEvent(err);
      });
    }
  } catch (err) {
    analytics.logEvent("failed_pre-approval_attempt");
    dispatch({
      type: "RESPONSE",
      payload: {
        id: "SEND_INFO_ERROR",
        msg: "An error occured",
        status: err.status,
        type: "error",
      },
    });
  }
};

export const preSubscribeUser = () => async (dispatch, getState) => {
  try {
    await API.preSubscribeUser(getState().user.email.toLowerCase());

    // const kol_popup = document.querySelector(
    //   'kol-contestbox-contest-frame-holder'
    // )

    // kol_popup.style.zIndex = -1

    document
      .querySelector("#blacksteel-form")
      .addEventListener("submit", (e) => {
        e.preventDefault();
      });
    document.querySelector("#submit-form").click();
  } catch (err) {
    console.log(err);
  }
};

export const getApprovalAmount = () => async (dispatch, getState) => {
  try {
    const { income, approvalAmount } = getState().user;

    const result = await API.getApprovalAmount({ income, approvalAmount });

    dispatch({
      type: "INPUT_CHANGE",
      payload: {
        name: "approvalAmount",
        value: result.data.approvalAmount,
      },
    });
  } catch (err) {
    console.log(err);
  }
};

export const getKolInfo = () => async (dispatch, getState) => {
  try {
    const { email } = getState().user;

    const result = await API.getKolInfo({ email: email.toLowerCase() });

    dispatch({
      type: "INPUT_CHANGE",
      payload: {
        name: "kolInfo",
        value: result.data.kolInfo,
      },
    });
  } catch (err) {
    console.log(err);
  }
};

export const checkUserExistence = () => async (dispatch, getState) => {
  const user = getState().user;

  const response = await API.getUsersByEmail({ email: user.email, limit: 1 });
  let payload, registered;
  if (response.data.users.length) {
    payload = response.data.users[0];
    registered = true;
    document.querySelector("#submit-form").click();
  } else {
    payload = { inexistent: true };
    registered = false;
  }
  setTimeout(() => {
    dispatch({ type: "CHECKED_USER_EXISTENCE", payload });
    dispatch({
      type: "INPUT_CHANGE",
      payload: { name: "registered", value: registered },
    });
  }, 1500);
};
