import React, { useState } from 'react'
import Typer from 'react-typing-effect'

import TextField from '../../../components/text-field/textField.component'
import Button from '../../../components/button/button.component'
import { emailIsValid } from '../../../assets/js/helpers'

import { inputChange, preSubscribeUser } from '../../../redux/actions/user.actions'
import { connect } from 'react-redux'

import '../css/homepageFooter.css'
import Toolbar from '../../../components/toolbar/toolbar.component'

const Header = props => {
  const { email } = props
  const [autoComplete, setAutoComplete] = useState('new-password')
  const handleEmailInput = e => {
    props.inputChange('email', e.target.value)
  }

  const showDialog = () => {
    let message = ''
    if (!email) message = 'Please enter your email address'
    else if (!emailIsValid(email)) {
      message = 'Please check that your email is valid'
    }
    if (message) {
      props.onShowSnackbar(message)
      return
    }
    props.onShowDialog()
    props.preSubscribeUser()
  }

  return (
    <div className='page-header general-body'>
      <div>
        <Toolbar home/>

        <div className='header-content'>
          <div>
            <h1 className='headline'>
              A Premium Rewards Credit Card for <br className='word-break' />
              <span>
                <Typer
                  text={[
                    'Workers.',
                    'Creators.',
                    'Hustlers.',
                    'Entrepreneurs.'
                  ]}
                  eraseDelay={400}
                  typingDelay={100}
                  speed={100}
                  eraseSpeed={80}
                  displayTextRenderer={text => {
                    return (
                      <span
                        style={{
                          whiteSpace: 'no-wrap',
                          display: 'inline-block'
                        }}
                      >
                        {text.split('').map((char, i) => {
                          const key = `${i}`
                          return (
                            <span
                              key={key}
                              style={char === '.' ? { color: 'white' } : {}}
                            >
                              {char}
                            </span>
                          )
                        })}
                      </span>
                    )
                  }}
                />
              </span>
            </h1>
            <p className='about'>
              Get all of the benifits of a top tier credit card with no
              downsides. No Credit Checks, Fee’s, or APR%. Get pre-approved
              today risk-free.
            </p>

            <div className='textfield-button'>
              <form id='blacksteel-form' className='textfield'>
                <TextField
                  value={email}
                  onInput={handleEmailInput}
                  color='white'
                  type='email'
                  name='email'
                  placeholder='Enter Email Address...'
                  autoComplete={autoComplete}
                  onFocus={() => {
                    setAutoComplete('email')
                  }}
                  onBlur={() => {
                    setAutoComplete('new-password')
                  }}
                />
                <input
                  id='submit-form'
                  type='submit'
                  style={{
                    visibility: 'hidden',
                    position: 'absolute',
                    zIndex: -1
                  }}
                />
              </form>
              <Button block className='button' onClick={showDialog}>
                Get Pre-Approved
              </Button>
            </div>
            <p className='secondary-light-color'>
              Blacksteel will never pull your credit.
            </p>
          </div>
          <img src={'/images/Hero Phone.png'} alt='' />
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = state => ({
  email: state.user.email
})
export default connect(mapStateToProps, { inputChange, preSubscribeUser })(Header)
