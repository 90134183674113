import React, { useEffect } from 'react'
import './css/snackbar.css'

let timeout_
const Snackbar = props => {
  const { message, show, timeout, type } = props

  useEffect(() => {
    clearTimeout(timeout_)
    if (show) {
      timeout_ = setTimeout(closeSnackbar, timeout || 2500)
    }
  }, [show])

  const closeSnackbar = () => {
    props.onClose()
  }

  return (
    <div id='snackbar' className={`${show ? 'show' : ''} ${type ? type : 'error'}`}>
      <div>{message}</div>
    </div>
  )
}
export default Snackbar
