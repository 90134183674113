import React from 'react'
import './css/button.css'

const Button = props => {
  const handleClick = () => {
    if (props.onClick) props.onClick()
  }
  return (
    <button
      id='button'
      className={`${props.className} ${props.loading ? 'loading' : ''} ${
        props.disabled ? 'disabled' : ''
      }`}
      style={{
        backgroundColor: !props.outlined
          ? props.color || 'var(--primary-color)'
          : 'inherit',
        color: props.outlined
          ? props.color || 'var(--primary-color)'
          : props.contentColor || 'white',
        boxShadow: props.outlined
          ? !props.disabled
            ? `inset 0 0 0 1px ${props.color || 'var(--primary-color)'}`
            : 'inset 0 0 0 1px #B3B3B3'
          : !props.depressed
          ? '0 2px 5px rgba(0, 0, 0, 0.2)'
          : 'none',
        borderRadius: '5px',
        padding: props.padding || '0.8em 1.5em',
        display: props.block ? 'block' : 'initial',
        width: props.block ? '100%' : 'auto',
        ...props.style
      }}
      onClick={handleClick}
      disabled={props.disabled}
      type={props.type}
    >
      <div
        className='loader'
        style={{
          borderBottom: `2px solid ${
            props.disabled
              ? 'rgba(199, 199, 199, 0.47)'
              : props.color || 'var(--primary-color)'
          }`
        }}
      ></div>
      <div className='children d-flex align-items-center'>{props.children}</div>
    </button>
  )
}

export default Button
