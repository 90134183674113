import React from 'react'
import Toolbar from '../../components/toolbar/toolbar.component'

const TermsAndConditions = () => {
  
  window.scrollTo(0, 0);

  return (
    <>
      <Toolbar />
      <div className='general-body'>
        <div style={{ marginTop: 100, marginBottom: 80 }}>
          <h1>Terms And Conditions</h1>

          <h2>Pre-Approval and Waitlist</h2>
          <p>
            Blacksteel's pre-approval is an estimated pre-approval amount based
            on high-level financial information that you provided. Blacksteel is
            in no way financially obligated to honor any approval amount. Upon
            launch, pre-approved customers will go through our actual approval
            process which will require connecting your bank account to attain
            actual and accurate financial data for us to provide you with your
            final approval amount.
          </p>

          <h2>Credit Limits</h2>
          <p>
            Blacksteel's credit limits are dynamic and can increase or decrease,
            daily, weekly, or monthly depending on any changes to your cash
            flow, income, and / or spending Blacksteel deems significant. Any
            approved credit limit amount is considered a baseline and you should
            expect your active credit limit to stay at or around your initial
            approved credit limit.
          </p>

          <h2>Deactivation & Termination</h2>
          <p>
            In order to keep your card and account from being deactivated and /
            or terminated you must:
          </p>
          <ol>
            <li>
              Keep an active bank account connected to the Blacksteel platform
            </li>
            <li>
              Pay any Blacksteel Credit Card balance in full at the end of every
              30-day term.
            </li>
          </ol>

          <h2>Fees & Interest</h2>
          <p>
            Blacksteel charges absolutely no fees on any credit card accounts.
            <br />
            Transaction Fees = $0
            <br />
            Annual or Maintenance Fees = $0
            <br />
            APR = 0%
          </p>

          <h2>Referal Program</h2>
          <p>
            Blacksteel's referral program targets are subject to change at any
            moment with or without notice. Most changes will not affect any
            previously subscribed / pre-approved customers on our waitlist but
            in some rare cases might be applied to previous customers and / or
            all customers, present and future, on our waitlist with or without
            notice.
          </p>
          <p>Blacksteel's referral program consist of 3 different rewards:</p>
          <p>
            <b>$30 for you and the person you referred</b>
            <br /> For any person you refer, both you and the person referred
            will receive a $30 credit to their Blacksteel credit card upon final
            approval for Blacksteel's credit card and $3,000 of spend and
            successful payment of all balances in full.
          </p>
        </div>
      </div>
    </>
  )
}

export default TermsAndConditions
