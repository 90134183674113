import axios from 'axios'

const URL = 'https://us-central1-useblacksteel.cloudfunctions.net/app/api/v1/'
// const LocalURL = 'http://localhost:5000/api/v1/'

export default {
  url: URL,
  headers (fileupload = false) {
    let header = {}
    header['Content-type'] = 'application/json'
    header['Accept'] = '*/*'
    header['Access-Control-Allow-Origin'] = '*'
    return header
  },

  addUser (data) {
    return axios({
      url: `${this.url}users`,
      method: 'post',
      headers: this.headers(),
      data
    })
  },
  preSubscribeUser (email) {
    return axios({
      url: `${this.url}users/pre-subscribe`,
      method: 'post',
      headers: this.headers(),
      data: { email }
    })
  },
  getApprovalAmount (params) {
    return axios({
      url: `${this.url}users/approval-amount`,
      method: 'get',
      headers: this.headers(),
      params
    })
  },
  getKolInfo (params) {
    return axios({
      url: `${this.url}users/kol-info`,
      method: 'get',
      headers: this.headers(),
      params
    })
  },
  getUsersByEmail (params) {
    return axios({
      url: `${this.url}users/get-by-email`,
      method: 'get',
      headers: this.headers(),
      params
    })
  }
}
