const initialState = {
  email: '',
  firstName: '',
  lastName: '',
  income: '',
  accountBalance: '',
  phoneNumber: '',
  referralCode: '',
  dbUser: null,
  registered: false,
  kolInfo: {},
  approvalAmount: null,
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INPUT_CHANGE':
      return {
        ...state,
        [action.payload.name]: action.payload.value
      }
    case 'SET_REFERRAL_CODE':
      return {
        ...state,
        referralCode: action.payload.referralCode
      }
    case 'CHECKED_USER_EXISTENCE':
      return {
        ...state,
        dbUser: action.payload
      }
    case 'CLEAR_STATE':
      return initialState
    default:
      return state
  }
}

export default userReducer
