import React from 'react'
import { Provider } from 'react-redux'
import store from './redux/store'
import { analytics } from './assets/js/firebaseConfig'

import './App.css'
import Navigation from './components/navigation.component'

const App = () => {
  analytics.logEvent('visit')
  return (
    <Provider store={store}>
      <Navigation />
    </Provider>
  )
}
export default App
