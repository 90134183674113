import React from 'react'

const Step = props => {
  const { currentStep, step, totalSteps, stepName, isMobile } = props

  const isActive = step === currentStep
  const isDone = step < currentStep
  const isPending = step > currentStep

  return isMobile ? (
    <div
      style={{
        padding: `0 0 ${step === totalSteps ? '0' : '2em'}`,
        display: 'flex',
        backgroundImage: 'linear-gradient(white,white)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: `2px ${step !== totalSteps ? '100%' : '0'}`,
        backgroundPosition: '0.75em 2px'
      }}
    >
      <div style={{ width: '3.2em' }}>
        <div
          style={{
            maxWidth: currentStep >= step ? '1.5em' : '1em',
            minWidth: currentStep >= step ? '1.5em' : '1em',
            maxHeight: currentStep >= step ? '1.5em' : '1em',
            minHeight: currentStep >= step ? '1.5em' : '1em',
            backgroundColor:
              currentStep > step ? 'var(--primary-color)' : 'white',
            borderRadius: '50%',
            marginLeft: currentStep >= step ? 0 : '0.3em',
            display: 'flex'
          }}
        >
          <span style={{ margin: 'auto' }}>
            {step === currentStep ? (
              step
            ) : step < currentStep ? (
              <img src={'/images/check.png'} />
            ) : (
              ''
            )}
          </span>
        </div>
      </div>
      <div>
        <span
          style={{ color: 'var(--secondary-light-text)', fontSize: '.9em' }}
        >
          Step {step}/{totalSteps}
        </span>
        <div style={{ color: 'var(--primary-light-text)' }}>{stepName}</div>
      </div>
    </div>
  ) : (
    <div
      style={{
        width: '100%',
        backgroundColor: isActive ? 'white' : '',
        padding: '1.2em 2em',
        position: 'relative'
      }}
    >
      <div style={{ textAlign: 'right' }}>
        <span
          style={{
            color: !isActive
              ? 'var(--secondary-light-text)'
              : 'var(--primary-dark-text)',
            fontSize: '.9em'
          }}
        >
          Step {step}/{totalSteps}
        </span>
        <div
          style={{
            color: !isActive
              ? 'var(--primary-light-text)'
              : 'var(--secondary-dark-text)'
          }}
        >
          {stepName}
        </div>
        <div
          style={{
            maxWidth: '1.5em',
            minWidth: '1.5em',
            maxHeight: '1.5em',
            minHeight: '1.5em',
            backgroundColor: isDone
              ? 'var(--primary-color)'
              : isActive
              ? 'black'
              : 'white',
            color: !isActive ? 'black' : 'white',
            borderRadius: '50%',
            display: 'flex',
            position: 'absolute',
            right: '-0.75em',
            top: 'calc(50% - 0.75em)',
            border: isPending ? '1px solid black' : ''
          }}
        >
          <span style={{ margin: 'auto' }}>
            {!isDone ? step : <img src={'/images/check.png'} />}
          </span>
        </div>
      </div>
    </div>
  )
}

export default Step
