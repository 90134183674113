import React from 'react'
import './css/benefit.css'

const Benefit = props => {
  const { title, info, imageName } = props.benefit
  return (
    <div className='benefit'>
      <div>
        <div className='img-container'>
        <img src={`/images/${imageName}`} alt='' /></div>
      </div>
      <div>
        <h3>{title}</h3>
        <p>{info}</p>
      </div>
    </div>
  )
}
export default Benefit
