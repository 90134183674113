import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import HomePage from '../pages/home/homepage.page'
import PrivacyPolicy from '../pages/privacy-policy/privacy-policy.page'
import TermsAndConditions from '../pages/terms-and-conditions/terms-and-conditions.page'

const MyNav = props => {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path='/' component={HomePage} />
          <Route exact path='/privacy-policy' component={PrivacyPolicy} />
          <Route
            exact
            path='/terms-and-conditions'
            component={TermsAndConditions}
          />
        </Switch>
      </Router>
    </>
  )
}

export default MyNav
