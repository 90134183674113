import React from 'react'
import './css/card.css'

const Card = props => {
  const { name, info, imageName } = props.card
  const { index } = props

  return (
    <div className={`card ${index % 2 === 0 ? 'even' : ''}`}>
      <div>
        <div>
          <h3>{name}</h3>
          <p>{info}</p>
        </div>
      </div>
      <img src={`/images/${imageName}`} alt='' />
    </div>
  )
}
export default Card
