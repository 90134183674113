import React from 'react'
import Toolbar from '../../components/toolbar/toolbar.component'

const personalInfoProcesses = [
  'Filling out an application and creating a Blacksteel Account',
  'Making a purchase using your physical or virtual Blacksteel Card',
  'Using Blacksteel Accounts (such as sending or receiving funds via ACH, Wire, or Check, or investing in securities)',
  'Browsing useblacksteel.com',
  'Using our iOS or Android mobile apps',
  'Connecting accounts to your Blacksteel Account',
  'Calling, chatting, or interacting with Blacksteel (such as with our Customer Experience or Sales, or texting us a copy of a receipt)',
  'Interacting with us at a conference, webinar, or event'
]

const personalInfo = [
  'Your name',
  'Your email',
  'Your phone number',
  'Your address',
  'Your date of birth',
  'Your password (hashed) - unique, long, and strong, please',
  'Information related to your company, including the company’s legal name, address, copies of your company’s corporate documents, and Employer Identification Number (EIN)',
  'Bank account information',
  'Other information pertaining to your identity such as copies of a passport, driver’s license, or other national ID',
  'Your Tax Identification Number (TIN) or your Social Security Number (SSN)',
  'Personal information provided to us from third-party companies we partner with to offer services to you (see below).'
]

const requiredPersonalInfo = ['Your name', 'Your email', 'Your phone number']

const transactionInfo = [
  'Payment method information (card number, card type, PIN)',
  'Purchase amount',
  'Purchase date',
  'Purchase location',
  'Whether you made the purchase online or in a store',
  'Receipt information',
  'Information provided to us by the card network associated with transactions, merchants, or cardholders'
]

const browserInfo = [
  'IP address',
  'Mobile and other hardware or device identifiers',
  'Operating system',
  'Browser information, such as type and version',
  'Referring and exit pages, including landing pages and pages viewed',
  'Device event information, including crash reports, request and referral URLs and system activity details'
]

const webAndMobileInfo = [
  'Your location',
  'What you click on and what you type, time you spend on our websites, and other user experience information'
]

const cookiesAndTrackerInfo = [
  'Provide you services such as those that allow you to chat with our Customer Experience team',
  'Perform website and mobile analytics',
  'Improve the advertisements you see',
  'Help prevent fraud and prevent attacks against our websites and services',
  'Advertise and promote Blacksteel products and services, and additional products and services from our partner companies'
]

const cookieRecords = [
  'Websites you visit',
  'Length of time the advertisement was visible',
  'IP Address'
]

const callOrMailInfo = [
  'Your name',
  'Your email, phone number, or other contact information',
  'Content of your communications, including, in some cases recorded calls, and survey responses'
]

const webinarInfo = [
  'Your name',
  'Your email or other contact information',
  'Registration date and time',
  'Webinar attendance information, when you joined a webinar, when you left, if you were paying attention, and what country you joined from'
]

const contestAndPromotionInfo = [
  'Your name',
  'Your email, phone number, or other contact information',
  'Other personal information required for you to participate'
]

const blacksteelAccountInfo = [
  'Information associated with your Blacksteel Account',
  'Available funds',
  'Lifetime earned yield',
  'Available points'
]

const ACHInfo = [
  'Recipient name or company',
  'Sender name or company',
  'Amount sent',
  'Amount received',
  'Routing and account numbers',
  'Recipient bank and account name',
  'Account type',
  'Other information generated when a payment is made through the ACH Network'
]

const thirdPartyCompliances = [
  'To comply with regulatory and reporting obligations',
  'To facilitate your use of our products or services, or other products and services',
  'To underwrite your account',
  'To conduct fraud monitoring, prevention, and detection',
  'To fulfill commitments to our business partners',
  'To fulfill requests made by you'
]

const thirdPartyRegulatoryInfo = [
  'Your name',
  'Your phone number',
  'Your SSN',
  'Your current or previous addresses',
  'Your date of birth'
]

const bankAccountInfo = [
  'Unique identifiers associated with your bank',
  'Account names',
  'Bank account type',
  'Transaction history and related information',
  'Account balances',
  'Bank routing and account numbers'
]

const partnerInfo = [
  'You and your relationship with the business partner',
  'Your email or other contact information',
  'Your membership or relationship status with the business partner'
]

const personalInfoUses = [
  'Communicate with you about the products and services by phone, text, email, or chat',
  'Monitor, prevent, and detect fraud or unauthorized activity',
  'Secure our systems and products',
  'Mitigate financial loss, claims, liability, and other harm to our users, Blacksteel, our partners, or third parties',
  'Manage, operate, and improve the performance of our products and services',
  'Determine eligibility for Blacksteel products or services',
  'Help you make or receive a payment via ACH, wire, or check',
  'Use Blacksteel information to underwrite Blacksteel Card',
  'Provide you with tailored recommendations',
  'Identify, fix, and troubleshoot bugs and errors',
  'Facilitate reporting, analyze performance, and auditing',
  'Understand how you use our services so we can make enhancements or improvements',
  'Facilitate business relationships or comply with contractual obligations',
  'Comply with our regulatory, legal, and other compliance obligations',
  'Advertise or market services and products to you',
  'Determine eligibility for, calculate, and provide Blacksteel Rewards',
  'Compare information for accuracy and verify it with third parties',
  'If you elect to share information about your geolocation, we will use this information to enhance the security of our products and services and to prevent fraud'
]

const supportProvisions = [
  'Help identify and troubleshoot problems with your account',
  'Survey your opinions through surveys or questionnaires',
  'Communicate with you and respond to your specific requests or questions',
  'Manage and send you notifications, confirmations and important information about your account and our products and services'
]

const personalInfoShareReasons = [
  'To operate Blacksteel’s products and services or support general operations of Blacksteel’s business',
  'To integrate the products and services of our business partners with your Blacksteel Account',
  'To use third-party cloud services to host our website and store the information we process about you',
  'For any other legitimate business purpose'
]

const lawComplianceInfo = [
  'to comply with applicable law, regulation or payment network rules',
  'to enforce our contractual rights or comply with contractual obligations',
  'to protect the rights, privacy, safety and property of Blacksteel, you, our customers, our business partners, or others',
  'to respond to requests from auditors, courts, law enforcement agencies, regulators, and other public and government authorities, which may include authorities outside your country of residence. When complying with court orders and other similar legal processes, Blacksteel strives for transparency. We will make reasonable efforts to notify our customers and users of any disclosure of their personal information, unless we are prohibited by law, court order, or exigent circumstances prevent us from doing so.'
]

const californianRights = [
  'Access a copy of any personal information we have about you',
  'Delete any personal information we have about you when we do not have legal or contractual obligations to keep the information or a need for the information to carry out a legitimate business function',
  'Correct any personal information we have about you either in your settings, by contacting your Blacksteel Account administrator, or by contacting us as provided below'
]

const PrivacyPolicy = () => {

  window.scrollTo(0, 0);

  return (
    <>
      <Toolbar />
      <div className='general-body'>
        <div style={{ marginTop: 100, marginBottom: 80 }}>
          <h1>1. Personal Information We Process and How We Process It</h1>
          <p>
            The personal information we process depends on the products you use
            and how you access them. We process information when you directly
            interact with Blacksteel in the following ways:
          </p>
          <ul>
            {personalInfoProcesses.map(process => (
              <li>{process}</li>
            ))}
          </ul>

          <p>Information We Process When You Use Our Products and Services</p>
          <p>
            Information needed to apply and create a Blacksteel Account If you
            are opening a Blacksteel Account, we need to process a certain
            amount of preliminary information about you, your company, and
            individuals associated with your company to create a Blacksteel
            Account to comply with our legal and regulatory obligations. When
            you apply for a Blacksteel Account, we may process:
          </p>
          <ul>
            {personalInfo.map(info => (
              <li>{info}</li>
            ))}
          </ul>
          <p>Information needed to use our products and services</p>
          <p>
            We need to process personal information when your company assigns
            you a Blacksteel Card or otherwise allows you to use our products
            and services (for example as an employee or authorized
            representative). To use these services, we may process:
          </p>
          <ul>
            {requiredPersonalInfo.map(info => (
              <li>{info}</li>
            ))}
          </ul>
          <p>Information processed when you make a transaction</p>
          <p>
            Each time you make a payment using a Blacksteel Card, we receive
            information about your transaction. We may receive this information
            directly from you or from our financial partners or the card
            networks (Mastercard). We may process:
          </p>
          <ul>
            {transactionInfo.map(info => (
              <li>{info}</li>
            ))}
          </ul>
          <p>
            Information automatically processed when you visit our websites or
            use our mobile app
          </p>
          <p>
            We process personal information automatically when you visit our
            website or use our mobile app to make our services work, to prevent
            fraud and enhance security, to understand your preferences, and to
            tailor services to you for the best possible experience. We may
            process: Browser and device information.
          </p>
          <ul>
            {browserInfo.map(info => (
              <li>{info}</li>
            ))}
          </ul>

          <p>Information about your use of our website or mobile app.</p>
          <ul>
            {webAndMobileInfo.map(info => (
              <li>{info}</li>
            ))}
          </ul>

          <p>
            Cookies and trackers. We also use cookies and other activity
            tracking tools to help our website function effectively. These tools
            allow us to:
          </p>
          <ul>
            {cookiesAndTrackerInfo.map(info => (
              <li>{info}</li>
            ))}
          </ul>

          <p>
            We use cookies and trackers to assign you a unique identifier so we
            can record:
          </p>
          <ul>
            {cookieRecords.map(info => (
              <li>{info}</li>
            ))}
          </ul>
          <p>
            You can block these at any time using your web browser settings, but
            doing so may limit browsing experience and your ability to use
            certain features of our services.
          </p>

          <p>Information processed when you interact with Blacksteel teams</p>

          <p>
            We may process personal information about you when you call, chat,
            email, or interact with members of Blacksteel teams (such as
            Customer Experience, Sales, or Marketing), including:
          </p>
          <ul>
            {callOrMailInfo.map(info => (
              <li>{info}</li>
            ))}
          </ul>

          <p>Information processed when you join a webinar or event</p>
          <p>
            We may process personal information about you when you join
            Blacksteel at an event in person or online via a webinar. We may
            collect this information directly or obtain it from a third-party
            service provider. We may process:
          </p>
          <ul>
            {webinarInfo.map(info => (
              <li>{info}</li>
            ))}
          </ul>

          <p>
            Information processed when you enter into a sweepstakes, contest, or
            other promotion
          </p>
          <p>
            We may process personal information when you enter a sweepstakes,
            contest, or promotion to administer those programs, including:
          </p>
          <ul>
            {contestAndPromotionInfo.map(info => (
              <li>{info}</li>
            ))}
          </ul>

          <p>Information processed when you use Blacksteel</p>
          <p>
            Blacksteel may process personal information about you when you use
            Blacksteel to deposit or send checks, send or receive an ACH or
            wire, or interact with Blacksteel Account sales or customer
            experience representatives. We may process:
          </p>
          <p>Blacksteel Account and Blacksteel Information</p>
          <ul>
            {blacksteelAccountInfo.map(info => (
              <li>{info}</li>
            ))}
          </ul>

          <p>Information related to an ACH payment.</p>
          <ul>
            {ACHInfo.map(info => (
              <li>{info}</li>
            ))}
          </ul>

          <p>
            Information Processed from Our Business Partners and Service
            Providers
          </p>
          <p>
            We process personal information we receive from financial partners,
            identity verification services, and other third-party service
            providers, and information you share with us about your use of their
            products and services:
          </p>
          <ul>
            {thirdPartyCompliances.map(info => (
              <li>{info}</li>
            ))}
          </ul>

          <p>
            Information necessary to comply with regulatory and legal
            requirements
          </p>
          <p>
            When you open a Blacksteel Account as a company owner or an
            administrator on behalf of a company, or you provide information of
            an individual with an ownership interest in a company, we process
            personal information we receive from identity verification services
            and other third-party service providers that we must keep to comply
            with the law, including:
          </p>
          <ul>
            {thirdPartyRegulatoryInfo.map(info => (
              <li>{info}</li>
            ))}
          </ul>

          <p>
            Information processed to integrate third-party products and services
            with your Blacksteel Account
          </p>
          <p>
            When you choose to link your Blacksteel Account with products and
            services provided by our business partners, we process personal
            information we receive from them to ensure our products and services
            work properly together.
          </p>
          <p>
            Your bank. To underwrite, provide you the highest credit limit
            possible, and determine your eligibility for Blacksteel Rewards, we
            require you to give us information about your company’s finances and
            access to your bank account. We use third-party service providers to
            process information about your company’s bank account activity. When
            you link your bank account, we may process the bank account
            credentials and all information associated with the bank accounts
            associated with those credentials, including:
          </p>
          <ul>
            {bankAccountInfo.map(info => (
              <li>{info}</li>
            ))}
          </ul>

          <p>
            If you do not link your bank directly, we process information you
            provide with copies of your bank statements and other financial
            information about your company .
          </p>
          <p>
            The information we collect may differ based on the accounting
            service and the choices you make relating to that service.
          </p>
          <p>
            Program partners. We get information from financial service
            providers such as our issuing bank, card networks, and financial
            institutions that process payments on our behalf to make our
            services work. The information we get includes personal information
            related to your transactions using a virtual or physical Blacksteel
            Card as described above.
          </p>
          <p>
            Information processed to conduct fraud monitoring, prevention, and
            detection
          </p>
          <p>
            We process personal information from our business partners,
            financial service providers, credit reporting agencies, identity
            verification services, public sources, and other third parties to
            prevent fraud.
          </p>
          <p>
            Information processed as a result of a business partnership,
            referral, or agreement
          </p>
          <p>
            We enter into agreements with financial partners and other
            businesses that provide products and services related to ours, and
            as a result of these partnerships, Blacksteel may receive personal
            information related to that particular relationship or partnership.
            We may process information about:
          </p>
          <ul>
            {partnerInfo.map(info => (
              <li>{info}</li>
            ))}
          </ul>

          {/* Use of Info */}

          <h1>2. How We Use Personal Information</h1>

          <p>
            We process your personal information for the reasons stated
            specifically in this policy, but we may also process personal
            information about you to:
          </p>
          <p>Operate our business and provide tailored services to you.</p>
          <ul>
            {personalInfoUses.map(info => (
              <li>{info}</li>
            ))}
          </ul>

          <p>Provide you with support.</p>
          <ul>
            {supportProvisions.map(info => (
              <li>{info}</li>
            ))}
          </ul>
          <p>Anonymized and aggregated data</p>
          <p>
            To better target advertisements, improve, and market our products
            and services, and for other promotional purposes, we may reasonably
            transform personal information into de-identified information
            removing or masking information that could be used to identify you
            and by aggregating or combining de-identified data with other
            information. If you don’t want us to use your data in anonymized or
            aggregate form for promotional purposes, contact us at
            privacy@useblacksteel.com.
          </p>

          <h1>3. How We Share Personal Information</h1>

          <p>Sharing for business purposes only</p>
          <p>
            Blacksteel may provide personal information to third parties to
            improve our products and services, to operate our business, and to
            help Blacksteel market to potential customers. We may share all of
            the categories of information listed in this Privacy Policy with
            other companies:
          </p>
          <ul>
            {personalInfoShareReasons.map(info => (
              <li>{info}</li>
            ))}
          </ul>

          <p>No sale of personal information</p>

          <p>
            We do not and will not sell or allow our service providers to
            process your personal information for their own use without your
            express consent, unless the processing of that information is either
            required by law or we determine that disclosure is reasonably
            necessary to enforce our rights, protect our property or operations,
            or enforce the rights and protect the property or operations of our
            business partners and customers.
          </p>
          <p>Making a payment</p>
          <p>
            We share some personal information about you, such as your name and
            information about the payment method, with the person or merchant
            you are paying.
          </p>

          <p>Anonymous or aggregated information</p>
          <p>
            We may share anonymized or aggregated information, or other data
            that does not directly identify you or your company, with third
            parties.
          </p>
          <p>Affiliate sharing</p>
          <p>
            Blacksteel Inc. may share personal information we process about you
            with other Blacksteel companies to provide additional services to
            you, determine eligibility for new products and offers, and for
            other legitimate business interests.
          </p>
          <p>Service providers</p>
          <p>
            We share your personal information with our service providers that
            operate and provide services on our behalf. These service providers
            need access to your personal information to perform information
            processing, manage or enhance our customer information, tailor
            services to you and your preferences, and perform other services
            related to our business. We work with our service providers to make
            sure that they do not sell the personal information that we share
            with them.
          </p>
          <p>Advertising</p>
          <p>
            We may use third parties to send advertisements on our behalf. We
            may only share a very limited set of personal information required
            to deliver our advertisements to you (such as an email address to
            deliver the message to you), and we do not permit these third
            parties to use or share your personal information to advertise
            unaffiliated products or services to you. These third parties may
            also use cookies or similar tracking tools to identify you through
            your browser usage. While you may disable these tools in your
            browser settings, you may still receive non-targeted advertisements
            we sent as part of a general marketing campaign.
          </p>
          <p>Financial and business partners</p>
          <p>
            We share personal information to facilitate the services we provide
            to you with our business and financial partners.
          </p>
          <p>
            Card issuing partners. Blacksteel Inc. partners with banks to issue
            cards to you. Blacksteel owns and controls the transaction data and
            other personal information we share with card issuing partners. We
            share the personal information you provide in your application for a
            Blacksteel Account with our card issuing partners to comply with our
            contractual obligations and to facilitate compliance with their and
            our regulatory obligations. Our card issuing partners do not sell or
            use your personal information or use any related data to market or
            to develop products and services, or for any other purpose not
            authorized by us.
          </p>
          <p>
            Credit reporting agencies. We share information about you and your
            account with credit reporting agencies to verify information about
            your business, to report on your business’s performance, and to
            report late payments, missed payments, or other defaults, which in
            some cases may contain personal information.
          </p>
          <p>
            Card network partners. Mastercard separately processes information
            related to your use of your cards. For more information on how
            Mastercard handles your personal information, please visit
            Mastercard’s Global Privacy Notice, respectively.
          </p>
          <p>
            Partnerships and referrals. We share personal information to
            facilitate referral partnerships. For example, if you were referred
            to us through an organization we may provide confirmation to that
            organization that your company opened a Blacksteel Account with us,
            and information relating to your Blacksteel Account activity solely
            for the purpose of calculating any referral fees we owe related to
            the referral.
          </p>
          <p>
            Sharing authorized by you, your users, or authorized representatives
          </p>
          <p>
            We continually enhance our products and services by integrating with
            products and services provided by other companies. For these
            integrations to work and with your explicit consent, we may need to
            share your personal information with them. Information we share with
            these companies will be used and disclosed according to that
            company’s privacy policy. You should review the privacy policy of
            any company that has access to your personal information related to
            the integration with your Blacksteel Account.
          </p>
          <p>
            In the event of a corporate sale, merger, reorganization,
            dissolution or similar event, personal information and data we
            process from you may become part of the assets we transfer or share
            in preparation for any such transaction. Any acquirer or successor
            of Blacksteel may continue to process personal information
            consistent with this Privacy Policy.
          </p>

          <p>Compliance and compelled disclosure</p>
          <p>We may share personal information:</p>
          <ul>
            {lawComplianceInfo.map(info => (
              <li>{info}</li>
            ))}
          </ul>

          <h1>4. Where We Store Personal Information</h1>
          <p>
            We provide and direct our services only to companies incorporated in
            the United States and for use exclusively by their employees or
            authorized representatives. We may process and store personal
            information for the purposes described in this Privacy Policy in the
            United States or any other country in which Blacksteel, its
            subsidiaries, affiliates, or service providers operate. We will
            store this information while we continue to provide products and
            services to you, to operate our business, comply with contractual
            obligations, and for as long as required by law.
          </p>
          <p>
            If you ask us to delete specific personal information (see “Your
            Choices About Your Personal Information” below), we will honor your
            request unless deleting the information prevents us from billing for
            our products and services, calculating taxes, conducting required
            audits, or carrying out other legitimate business functions, or we
            are required by law or contractual obligation to keep that
            information.
          </p>

          <h1>
            5. Other Important Information About Your Personal Information
          </h1>
          <p>Security</p>
          <p>
            We use organizational, technical, and administrative measures to
            protect personal information; however, no data security program is
            entirely secure. Please contact us at privacy@useblacksteel.com
            immediately if you believe that your personal information or any
            other confidential information that you have provided to us is no
            longer secure or has been lost or stolen. If we confirm that there
            has been a breach of personal information or confidential data
            related to you, we will notify you.
          </p>

          <p>Use by minors</p>
          <p>
            If you are under the age of 16, you may not have a Blacksteel
            Account or use our products or services. We do not knowingly process
            any information from, or direct any of our products or services to
            children under the age of 16. Please do not provide us with any
            personal information related to children under the age of 16.
          </p>
          <p>
            Personal information related to administrators, employees, company
            owners, and authorized persons or representatives
          </p>

          <p>
            In some circumstances, we require you to provide us with personal
            information relating to another person (such as providing us with
            personal information about the owners of your business during the
            application process or providing us with personal information about
            your employees). Do not provide us with any personal information
            unless you have obtained consent of these persons or you are sure
            the disclosure of the personal information is otherwise permitted by
            law or contract. You must inform all other persons whose information
            you share with us how we process personal information and all other
            terms of this Privacy Policy.
          </p>

          <p>Blacksteel Account users</p>
          <p>
            Our products and services are intended for use only by companies,
            and you may only use a Blacksteel Account or Card if you are an
            employee or other authorized representative of a company that has
            opened a Blacksteel Account. The company’s administrator is
            responsible for the Blacksteel Account and cards associated with
            that company. An administrator can: restrict, suspend, or terminate
            your access to or ability to use the services, access personal
            information about you, access or retain information stored with us,
            and restrict your ability to edit, restrict, modify, or delete
            information associated with your use of our products and services.
          </p>

          <h1>6. Your Choices About Your Personal Information</h1>
          <p>Electronic communications</p>
          <p>
            You may choose not to receive promotional emails from us by
            following the unsubscribe/opt-out instructions in those emails, but
            we may still send you non-promotional messages relating to material
            or transactional information about our products and services such as
            updates to our platform agreement, privacy notices, security alerts,
            and other notices relating to your access to or use of our products
            and services.
          </p>
          <p>Deletion or closure of a Blacksteel Account</p>
          <p>
            Closure or deletion of your company’s Blacksteel Account will mean
            that you permanently lose access to the personal information and
            data associated with the Blacksteel Account. Certain personal
            information or de-identified information associated with your
            company’s Blacksteel Account may nonetheless remain on systems owned
            or maintained by Blacksteel where required to comply with the law,
            our contractual obligations, or carrying out legitimate business
            functions.
          </p>
          <p>Application rejection</p>
          <p>
            To comply with legal and regulatory obligations, we retain certain
            personal information associated with rejected applications for
            Blacksteel Accounts.
          </p>
          <p>California rights</p>
          <p>
            If you are a California resident, you have certain rights over the
            information that we have about you. You can:
          </p>
          <ul>
            {californianRights.map(info => (
              <li>{info}</li>
            ))}
          </ul>
          <p>Exercising your choices</p>
          <p>
            To exercise your choices about your personal information, please
            contact our Customer Experience team community@useblacksteel.com. We
            will need to verify your relationship to the Blacksteel Account (for
            example, if you’re an administrator of a Blacksteel Account) and
            verify your identity. Verification will require that you verify your
            email and phone number and provide a copy of a government issued ID
            to us. We may take additional steps to verify that you are
            authorized to make the request. If you are an end user of
            Blacksteel’s services and not a direct customer of Blacksteel (for
            example, your company uses Blacksteel and you’re an employee or
            authorized representative of that company), you should direct
            requests relating to your personal information to the administrator
            of your company’s Blacksteel Account. We will not discriminate
            against you for exercising any of your rights.
          </p>
          <p>
            Sale of personal information (“Do Not Sell My Personal
            Information”). We do not sell your personal information.
          </p>
        </div>
      </div>
    </>
  )
}

export default PrivacyPolicy
