import React from 'react'
import './css/target.css'

const Target = props => {
  const { title, info, imageName } = props.target

  return (
    <div className='target general-body'>
      <div className={`content${props.even ? ' even' : ''}`}>
        <div>
          <h1 className='title'>{title}</h1>
          <p className='info'>{info}</p>
        </div>
        <img src={`/images/${imageName}`} alt='' />
      </div>
    </div>
  )
}
export default Target
