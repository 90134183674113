import React, { useState } from 'react'
import TextField from '../../../components/text-field/textField.component'
import Button from '../../../components/button/button.component'
import { emailIsValid } from '../../../assets/js/helpers'

import { inputChange } from '../../../redux/actions/user.actions'
import { connect } from 'react-redux'

import facebook from '../../../assets/icons/Facebook.svg'
import twitter from '../../../assets/icons/Twitter.svg'
import instagram from '../../../assets/icons/Instagram.svg'
import '../css/homepageFooter.css'
import { Link } from 'react-router-dom'

const Footer = props => {
  const { email } = props
  const [autoComplete, setAutoComplete] = useState('new-password')

  const handleEmailInput = e => {
    props.inputChange('email', e.target.value)
  }

  const showDialog = () => {
    let message = ''
    if (!email) message = 'Please enter your email address'
    else if (!emailIsValid(email)) {
      message = 'Please check that your email is valid'
    }
    if (message) {
      props.onShowSnackbar(message)
      return
    }
    props.onShowDialog()
  }

  return (
    <div className='page-footer general-body'>
      <div>
        <div className='logo'>
          <img src={`/images/logo.png`} alt='logo' />
          <h1>BLACKSTEEL</h1>
        </div>
        <div className='content'>
          <h1 className='headline weight-normal primary-light-color'>
            Get Started Today
          </h1>
          <p className='about secondary-light-color'>
            Get pre-approved now to see your estimated credit limit and be apart
            of our first cohort of cards for exclusive rewards.
          </p>

          <div className='textfield-button'>
            <TextField
              value={email}
              onInput={handleEmailInput}
              color='white'
              placeholder='Enter Email Address...'
              className='textfield'
              autoComplete={autoComplete}
              onFocus={() => {
                setAutoComplete('email')
              }}
              onBlur={() => {
                setAutoComplete('new-password')
              }}
            />
            <Button block className='button' onClick={showDialog}>
              Get Pre-Approved
            </Button>
          </div>
          <div className='copyright-social'>
            <div className='copyright secondary-light-color center-text'>
              © 2021 BlackSteel, Inc. All rights reserved.
            </div>
            <div className='social-links'>
              <a href='https://www.facebook.com/useblacksteel' target='_blank'>
                <img src={facebook} alt='facebook' />
              </a>
              <a href='https://twitter.com/useblacksteel' target='_blank'>
                <img src={twitter} alt='twitter' />
              </a>
              <a href='https://www.instagram.com/useblacksteel' target='_blank'>
                <img src={instagram} alt='instagram' />
              </a>
            </div>
          </div>
          <div className='privacy-terms' style={{ fontSize: '0.85em' }}>
            <Link to={'/privacy-policy'} className='secondary-light-color'>
              Privacy policy
            </Link>
            <Link
              to={'/terms-and-conditions'}
              className='secondary-light-color'
              style={{ marginLeft: '1em' }}
            >
              Terms and Conditions
            </Link>
          </div>
        </div>
        <img src={`/images/footer-image.jpg`} alt='' />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  email: state.user.email
})
export default connect(mapStateToProps, { inputChange })(Footer)
