import React from 'react'
import Offer from '../../../components/offer/offer.component'
import Benefit from '../../../components/benefit/benefit.component'
import Target from '../../../components/target/target.component'
import Card from '../../../components/card/card.component'
import {
  offers_,
  benefits_,
  targets_,
  cards_
} from '../../../assets/js/homeContents'

const offers = offers_.map(offer => {
  return <Offer offer={offer} key={offer.title} />
})
const benefits = benefits_.map(benefit => {
  return <Benefit benefit={benefit} key={benefit.title} />
})
const targets = targets_.map((target, index) => {
  return <Target target={target} even={index % 2 !== 0} key={target.title} />
})
const cards = cards_.map((card, index) => {
  return <Card key={card.name} card={card} index={index + 1} />
})

const Body = () => {
  return (
    <div className='page-body'>
      <div className='first-section general-body'>
        <div>
          <h1 className='headline'>We're About Cash Flow, Not Credit.</h1>
          <p className='method-text'>
            Credit scores are an outdated way of underwriting credit cards.
            Blacksteel determines your credit limit by how much you earn, save,
            and typically spend.
          </p>
          <div className='grid'>{offers}</div>
        </div>
      </div>
      <div className='second-section general-body'>
        <div>
          <h1 className='headline'>
            Up to 5x Higher Credit Limits vs Other Cards.
          </h1>
          <div className='benefits grid'>{benefits}</div>
        </div>
      </div>
      <div className='third-section targets'>{targets}</div>
      <div className='fourth-section general-body'>
        <div>
          <h1 className='headline'>Here's your card</h1>
          <div className='grid cards'>{cards}</div>
        </div>
      </div>
    </div>
  )
}
export default Body
