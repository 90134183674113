import firebase from 'firebase'

export const firebaseConfig = {
  apiKey: 'AIzaSyDgojzdkxL6aacDeaqSdtoiOZgQ0znmvtA',
  authDomain: 'useblacksteel.firebaseapp.com',
  projectId: 'useblacksteel',
  storageBucket: 'useblacksteel.appspot.com',
  messagingSenderId: '1034230291450',
  appId: '1:1034230291450:web:73119236c9bc769f91a243',
  measurementId: 'G-M00YV80MRS'
}

!firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app()

export const analytics = firebase.analytics()
