import React, { useState, useRef } from 'react'
import './css/approvalDialog.css'
import TextField from '../text-field/textField.component'
import Button from '../button/button.component'
import { numberWithCommas, priceIsValid } from '../../assets/js/helpers'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { Link } from 'react-router-dom'

import {
  inputChange,
  clearState,
  sendInfo,
  getApprovalAmount,
  getKolInfo
} from '../../redux/actions/user.actions'
import { clearResponse } from '../../redux/actions/response.actions'
import { connect } from 'react-redux'

import Buttons from './sub-components/buttons.subcomponent'
import Step from './sub-components/step.subcomponent'
import Rewards from './sub-components/rewards.subcomponent'
import {
  FacebookShareButton,
  TwitterIcon,
  FacebookIcon,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  RedditShareButton,
  RedditIcon,
  EmailShareButton,
  EmailIcon
} from 'react-share'

const steps_ = [
  { name: 'Personal Information' },
  { name: 'Finance Information' },
  { name: 'Phone Number' }
]

const quote = 'Have you tried this new credit card? Blacksteel?'

const ApprovalDialog = props => {
  const {
    firstName,
    lastName,
    accountBalance,
    income,
    phoneNumber,
    approvalAmount,
    kolInfo,
    registered
  } = props

  const [currentStep, setCurrentStep] = useState(1)
  const [previousStep, setPreviousStep] = useState(1)

  const forms = useRef()
  const urlRef = useRef()

  const steps = isMobile =>
    steps_.map((step, index) => {
      return (
        <Step
          currentStep={currentStep}
          stepName={step.name}
          step={index + 1}
          totalSteps={steps_.length}
          isMobile={isMobile}
          key={step.name}
        />
      )
    })

  const incrementStep = () => {
    setPreviousStep(currentStep)
    setCurrentStep(currentStep + 1)
  }
  const decrementStep = () => {
    setPreviousStep(currentStep)
    setCurrentStep(currentStep - 1)
  }
  const copyUrl = () => {
    var copyText = urlRef.current

    copyText.select()
    copyText.setSelectionRange(0, 99999)

    document.execCommand('copy')

    props.onShowSnackbar('Url copied!', 'success', 1200)
  }

  const subscribeUser = () => {
    incrementStep()
    props.sendInfo(approvalAmount)
  }

  const submitNames = () => {
    let message = ''
    if (!firstName) message = 'Please enter your first name'
    else if (!lastName) message = 'Please enter your last name'

    if (message) {
      props.onShowSnackbar(message)
      return
    }
    incrementStep()
    props.getKolInfo()
  }

  const submitFinance = () => {
    let message = ''
    if (!income) message = 'Please enter your income'
    else if (!priceIsValid(income)) message = 'Please enter a valid income'
    else if (!accountBalance) message = 'Please enter your account balance'
    else if (!priceIsValid(accountBalance))
      message = 'Please enter a valid account balance'

    if (message) {
      props.onShowSnackbar(message)
      return
    }
    incrementStep()
    props.getApprovalAmount()
  }

  const submitPhoneNumber = () => {
    let message = ''
    if (!phoneNumber) message = 'Please enter your phone number'
    else if (!isValidPhoneNumber(phoneNumber, 'US'))
      message = 'Please enter a valid phone number'
    if (message) {
      props.onShowSnackbar(message)
      return
    }
    subscribeUser()
  }

  const showSocialShare = () => {
    if (kolInfo) {
      props.inputChange('registered', true)
    } else {
      closeDialog()
    }
  }

  const clearLocalState = () => {
    setCurrentStep(1)
    setPreviousStep(1)
  }

  const closeDialog = () => {
    clearLocalState()
    props.clearState()
    props.inputChange('dbUser', false)
    props.onClose()
  }
  const handleInput = e => {
    props.inputChange(e.target.name, e.target.value)
  }

  return (
    <div id='approval-dialog' className={`${props.show ? 'show' : ''}`}>
      <div id='container' className={registered ? 'registered' : ''}>
        {!registered ? (
          <div className='contents'>
            <div className='navigation'>
              <div className='mobile-nav'>{steps(true)}</div>
              <div className='tab-nav'>
                <div className='info'>
                  <p className='primary-light-color'>Get Pre-Approved</p>
                  <p className='secondary-light-color'>
                    To get Pre-Approved, please fill in the information needed
                    in 3 easy steps.
                  </p>
                </div>

                {steps()}
              </div>
            </div>
            <div ref={forms} className='forms'>
              <div className='close'>
                <img
                  src={'/images/close.png'}
                  alt='close'
                  className='pointer'
                  onClick={closeDialog}
                />
              </div>
              {currentStep === 1 ? (
                <div className='step-1'>
                  <div className='secondary-dark-color center-text'>
                    Step 1: Enter Personal Information
                  </div>
                  <div className='primary-dark-color center-text font-size-sm mt-2'>
                    We can't wait to get to know you!
                  </div>
                  <div className='mt-8'>
                    <div>First Name</div>
                    <TextField
                      outlined
                      value={firstName}
                      onInput={handleInput}
                      name='firstName'
                      className='mt-2'
                    />
                    <div className='mt-5'>Last Name</div>
                    <TextField
                      outlined
                      value={lastName}
                      onInput={handleInput}
                      name='lastName'
                      className='mt-2'
                    />
                    <div className='mt-6'>
                      <Buttons step={1} onContinue={submitNames} />
                    </div>
                  </div>
                </div>
              ) : currentStep === 2 ? (
                <div
                  className={`step-2 ${
                    previousStep === 1
                      ? 'is-forward'
                      : previousStep === 3
                      ? 'is-backward'
                      : ''
                  }`}
                >
                  <div className='secondary-dark-color center-text'>
                    Step 2: Enter Financial Information
                  </div>
                  <div className='primary-dark-color center-text font-size-sm mt-2'>
                    We will calculate the most suitable limit for you.
                  </div>
                  <div className='mt-8'>
                    <div>Monthly Income</div>
                    <TextField
                      value={income}
                      onInput={handleInput}
                      name='income'
                      outlined
                      className='mt-2'
                    />
                    <div className='mt-5'>Bank Account Balance</div>
                    <TextField
                      value={accountBalance}
                      onInput={handleInput}
                      name='accountBalance'
                      outlined
                      className='mt-2'
                    />
                    <div className='mt-6'>
                      <Buttons
                        step={2}
                        onContinue={submitFinance}
                        onGoBack={decrementStep}
                      />
                    </div>
                  </div>
                </div>
              ) : currentStep === 3 ? (
                <div className='step-3'>
                  <div className='secondary-dark-color center-text'>
                    Step 3: Enter Your Phone Number
                  </div>
                  <div className='primary-dark-color center-text font-size-sm mt-2'>
                    We will let you know once you have been approved.
                  </div>
                  <div className='mt-8'>
                    <div>Phone Number</div>
                    <TextField
                      value={phoneNumber}
                      onInput={handleInput}
                      name='phoneNumber'
                      outlined
                      className='mt-2'
                    />
                    <div className='mt-6'>
                      <Buttons
                        step={3}
                        continueText='Get Pre-Approved'
                        onContinue={submitPhoneNumber}
                        onGoBack={decrementStep}
                      />
                    </div>
                  </div>
                  <div
                    style={{ fontSize: '0.7em', marginTop: '2em' }}
                    className='center-text'
                  >
                    By clicking "Get Pre-Approved", you agree to Blacksteel&nbsp;
                    <Link to={'/terms-and-conditions'} target="_blank">
                      Terms of Use
                    </Link> and&nbsp;
                    <Link to={'/privacy-policy'} target="_blank">Privacy Policy</Link>. You
                    consent to receive SMS messages from Blacksteel for updates
                    and/or marketing purposes. You may opt-out by texting
                    "STOP". Message and data rates may apply.
                  </div>
                </div>
              ) : (
                <div className='congrats center-text'>
                  <div className='logo' style={{ marginTop: '-16px' }}>
                    <img src={'/images/logo-black.png'} alt='logo' />
                    <h1>BLACKSTEEL</h1>
                  </div>
                  <p>Congrats! You have been pre-approved for</p>
                  <h1 className='primary-color' style={{ marginBottom: 16 }}>
                    ${numberWithCommas(approvalAmount)}
                  </h1>
                  <p style={{ marginBottom: 0 }}>
                    You’ll be receiving a follow-up text and email shortly with
                    more information.
                    <br />
                    <strong>Thanks for choosing Blacksteel.</strong>
                  </p>
                  <Button
                    padding='0.8em 3.7em'
                    className='mt-4'
                    onClick={showSocialShare}
                  >
                    Ok
                  </Button>
                  <div
                    style={{ fontSize: '0.7em', marginTop: '2em' }}
                    className='center-text'
                  >
                    Please note that this is an estimated Pre-Approval balance
                    based off whatever information you provided whether accurate
                    or not. Final approval amounts will be disclosed closer to
                    our launch date once actual banking details are connected
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div>
            <div className='referral-body'>
              <img
                src='/images/girl with cup.png'
                alt='girl with cup'
                className='girl-img'
              />
              <img
                src={'/images/close.png'}
                alt='close'
                className='pointer ref-close'
                onClick={closeDialog}
              />
              <div className='links'>
                <h3>
                  We Need Your Help
                  <span style={{ color: 'var(--primary-color)' }}>.</span>
                </h3>
                <p>
                  Share us with a friend, family, or Coworker. You’ll get early
                  access or become a premium card member.
                </p>
                <div style={{ position: 'relative' }}>
                  <div>
                    <div className='center-text'>
                      <FacebookShareButton
                        url={kolInfo.social_url}
                        quote={'Join Blacksteel today'}
                        className='social-icon'
                      >
                        <FacebookIcon size={36} round={true} />
                      </FacebookShareButton>
                      <TwitterShareButton
                        url={kolInfo.social_url}
                        quote={'Join Blacksteel today'}
                        className='social-icon'
                      >
                        <TwitterIcon size={36} round={true} />
                      </TwitterShareButton>
                      <WhatsappShareButton
                        url={kolInfo.social_url}
                        quote={'Join Blacksteel today'}
                        className='social-icon'
                      >
                        <WhatsappIcon size={36} round={true} />
                      </WhatsappShareButton>
                      <EmailShareButton
                        url={kolInfo.social_url}
                        quote={quote}
                        body={quote}
                        subject='Blacksteel Invite'
                        className='social-icon'
                      >
                        <EmailIcon size={36} round={true} />
                      </EmailShareButton>
                      <RedditShareButton
                        url={kolInfo.social_url}
                        quote={quote}
                        className='social-icon'
                      >
                        <RedditIcon size={36} round={true} />
                      </RedditShareButton>
                    </div>

                    <div className='copy-url-parent'>
                      <div>
                        <p>Share your unique link</p>
                        <div className='copy-url'>
                          <input
                            ref={urlRef}
                            style={{
                              padding: '0.8em 1.5em',
                              borderRadius: '5px',
                              border: '1px solid gray',
                              width: '100%'
                            }}
                            readOnly
                            value={kolInfo.social_url}
                          />
                          <Button
                            padding='0.8em 3em'
                            onClick={() => {
                              copyUrl()
                            }}
                            outlined
                          >
                            Copy Link
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='rewards-container'>
                <Rewards></Rewards>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  firstName: state.user.firstName,
  lastName: state.user.lastName,
  email: state.user.email,
  registered: state.user.registered,
  income: state.user.income,
  approvalAmount: state.user.approvalAmount,
  accountBalance: state.user.accountBalance,
  phoneNumber: state.user.phoneNumber,
  referralCode: state.user.referralCode,
  response: state.response,
  kolInfo: state.user.kolInfo
})
export default connect(mapStateToProps, {
  inputChange,
  clearState,
  sendInfo,
  clearResponse,
  getApprovalAmount,
  getKolInfo
})(ApprovalDialog)
