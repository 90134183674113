const initialState = {
  msg: '',
  status: 500,
  id: '',
  type: 'error'
}

const responseReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'RESPONSE':
      return { ...state, ...action.payload }
    case 'CLEAR_RESPONSE':
      return initialState
    default:
      return state
  }
}

export default responseReducer
