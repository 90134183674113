import React from 'react'
import Button from '../../button/button.component'

const Buttons = props => {
  const { formValid, continueText, step } = props

  return (
    <div className='buttons'>
      <Button
        block
        disabled={formValid}
        onClick={() => {
          props.onContinue()
        }}
      >
        {continueText || 'Next'}
      </Button>
      <Button
        outlined
        block
        disabled={step === 1}
        onClick={() => {
          props.onGoBack()
        }}
      >
        Back
      </Button>
    </div>
  )
}
export default Buttons
